import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import Rotas from './Rotas';
import axios from 'axios';

// import { initializeApp } from "firebase/app";
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";

// import "firebase/messaging";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyANbSPj5WKb2lYULBVMwIrmi49YYcIetuU",
//   authDomain: "podcast-tib.firebaseapp.com",
//   projectId: "podcast-tib",
//   storageBucket: "podcast-tib.firebasestorage.app",
//   messagingSenderId: "1011637093353",
//   appId: "1:1011637093353:web:7278cfab36f1701333c178",
//   measurementId: "G-KEKZ7BY5CE"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// // import firebase from "firebase/app";
// // import "firebase/messaging";



// // const firebaseConfig = {
// //   apiKey: "AIzaSyANbSPj5WKb2lYULBVMwIrmi49YYcIetuU",
// //   authDomain: "podcast-tib.firebaseapp.com",
// //   projectId: "podcast-tib",
// //   storageBucket: "podcast-tib.firebasestorage.app",
// //   messagingSenderId: "1011637093353",
// //   appId: "1:1011637093353:web:7278cfab36f1701333c178",
// // };

// // firebase.initializeApp(firebaseConfig);

// const messaging = getMessaging(app);

// const requestPermissionAndGetToken = async () => {
//   try {
//     // Solicita permissão para enviar notificações
//     const permission = await Notification.requestPermission();
//     if (permission === "granted") {
//       const token = await getToken(messaging, {
//         vapidKey: "BHfbhjx3sWP9yY97Zkg_xtAyJm4qQuz2Hc2b4YLGWJKc4USFBoS8P66ivO4eH8Zpa-NbBvzykzFFh9ALNq_LVU0",
//       });
//       console.log("Token do dispositivo:", token);
//       return token;
//     } else {
//       console.warn("Permissão para notificações negada.");
//     }
//   } catch (error) {
//     console.error("Erro ao obter o token:", error);
//   }
// };

// const sendNotification = async (token, title, body) => {
//   const serverKey = "AIzaSyANbSPj5WKb2lYULBVMwIrmi49YYcIetuU"; // Chave do servidor FCM
//   const notificationData = {
//     to: token,
//     notification: {
//       title,
//       body,
//     },
//   };

//   try {
//     const response = await axios.post(
//       "https://fcm.googleapis.com/fcm/send",
//       notificationData,
//       {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `key=${serverKey}`,
//         },
//       }
//     );
//     console.log("Notificação enviada com sucesso:", response.data);
//   } catch (error) {
//     console.error("Erro ao enviar notificação:", error);
//   }
// };

// // Chamar a função para obter o token
// const token = requestPermissionAndGetToken();
// if (token) {
//   // Enviar a notificação
//   await sendNotification(token, "Atualização no Banco", `Dados atualizados: exemplo de dados`);
// }

// onMessage(messaging, (payload) => {
//   console.log("Mensagem recebida em primeiro plano:", payload);
// });

// // messaging.onBackgroundMessage((payload) => {
// //   console.log("Mensagem recebida em segundo plano: ", payload);
// //   self.registration.showNotification(payload.notification.title, {
// //     body: payload.notification.body,
// //     icon: payload.notification.icon,
// //   });
// // });

// if ("serviceWorker" in navigator) {
//   navigator.serviceWorker.register("/firebase-messaging-sw.js").then((registration) => {
//     console.log("Service Worker registrado com sucesso:", registration);
//   }).catch((error) => {
//     console.error("Falha ao registrar o Service Worker:", error);
//   });
// }

// if ("serviceWorker" in navigator && "PushManager" in window) {
//   console.log("Push Notifications são suportadas!");
// } else {
//   console.error("Push Notifications não são suportadas neste navegador.");
// }



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    {/* <Router> */}
  <React.StrictMode>
    
    <Rotas />    
    {/* <Cadastrar />
    <Doadores /> */}
    {/* <Doadores /> */}
  </React.StrictMode>
  {/*  </Router> */}
   </BrowserRouter>
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
