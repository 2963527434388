import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import axios from "axios"
import { XMLParser } from "fast-xml-parser";
import Moment from './Moment';
import ReactPaginate from "react-paginate";


export default function (props) {

    // url teste local
    const baseurl = `https://podcast.tibmacae.org.br/ApiDoador.php`;

    //url em produção
    // const baseurl = `ApiDoador.php`;

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });


    const [duration, setDuration] = useState('Carregando');
    const audioRef = useRef(null);

    const [itemsjson, setItemsjson] = useState([]);

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState();

    const [filteredItems, setFilteredItems] = useState([]);

    const [filteredData, setFilteredData] = useState([]);
    const [masterData, setMasterData] = useState([]);

    const [busca, setBusca] = useState('');
    const [busca_transcricao, setBusca_transcricao] = useState('');
    const [data, setData] = useState('');
    const [id, setId] = useState('');

    const [audioData, setAudioData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const items = Array.from({ length: 100 }, (_, index) => `Item ${index + 1}`);


    const [currentPage, setCurrentPage] = useState(0);
    const [currentPageDefault, setCurrentPageDefault] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const itemsPerPage = windowSize.width >= 1500 ? 10 : windowSize.width < 1500 ? 6 : 5; // Itens por página

    const offset = currentPage * itemsPerPage;
    const currentItems = items.slice(offset, offset + itemsPerPage);


    const enviarAudioPlay = (teste) => {
        props.onClick(teste);
        props.enviarAudio(teste);
    };



    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Adiciona o listener de resize
        window.addEventListener('resize', handleResize);

        // Remove o listener ao desmontar o componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    async function getTime(arquivo) {
        const audio = new Audio(arquivo);
        const audioDuration = audio.duration;
        await new Promise((resolve) => {
            audio.onloadedmetadata = () => resolve();
        });

        // if (audioRef.duration) {
        setDuration(audio.duration);
        // }

        return audio.duration;

    }

    async function executaBusca_transcricao(page) {

        if (busca_transcricao !== props.busca_transcricao) {
            console.log("entrou no if buscar palavra");
            page = 0;
            setCurrentPage(0);
        }

        console.log('teste erro');
        console.log( props.busca_transcricao.palavra_transcricao);

        try {

            const config = {
                method: 'post',
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': '',
                    'palavratranscricao': props.busca_transcricao.palavra_transcricao,
                    'page': page + 1, // API trabalha com 1 como a primeira página
                    'limit': itemsPerPage,
                    'action': 'getpalavrastranscricao'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                console.log(res.data);
                console.log('tyrdyr items transcricao');
                for (let i = 0; i < res.data.items.length; ++i) {
                    // res.data.items[i].push({"teste": "teste"});
                    res.data.items[i].mp3 = "/Pregacoes/" + Moment(res.data.items[i].data).format("YY")
                        + "/" + Moment(res.data.items[i].data).format("MM") + "/" + res.data.items[i].id_youtube + ".mp3";

                    temp.push(res.data.items[i]);
                    console.log(res.data.items[i]);
                }

                setFilteredData(temp);
                setFilteredItems(temp);
                setMasterData(temp);
                setTotalPages(res.data.totalPages); // Total de páginas

            }).catch((err) => {
                executaBusca_transcricao(currentPage);
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                executaBusca_transcricao(currentPage);
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }

        setBusca_transcricao(props.busca_transcricao);

    }

    // useEffect(() => {     
    //     console.log('teste 555');
    //     console.log(props.tipo);
    //     console.log(props.id);
    //     console.log(busca);
    //     if (props.tipo == 'busca_transcricao' && props.id == null && props.busca == '') {
    //         console.log('teste props.busca_transcricao');
    //         console.log(props.busca_transcricao);
    //         executaBusca_transcricao(currentPage);
    //     }

    // }, [currentPage, props.busca_transcricao, props.tipo])


    async function executaBusca(page) {


        console.log('teste busca');
        console.log(busca);
        console.log(props.busca);
        if (busca !== props.busca) {
            console.log("entrou no if compara busca");
            page = 0;
            setCurrentPage(0);
        }

        console.log(page);

        try {

            const config = {
                method: 'post',
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': '',
                    'pastor': props.busca,
                    'page': page + 1, // API trabalha com 1 como a primeira página
                    'limit': itemsPerPage,
                    'action': 'getpalavrasporpastor'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                console.log(res.data);
                console.log('tyrdyr items');
                for (let i = 0; i < res.data.items.length; ++i) {
                    // res.data.items[i].push({"teste": "teste"});
                    res.data.items[i].mp3 = "/Pregacoes/" + Moment(res.data.items[i].data).format("YY")
                        + "/" + Moment(res.data.items[i].data).format("MM") + "/" + res.data.items[i].id_youtube + ".mp3";

                    temp.push(res.data.items[i]);
                    console.log(res.data.items[i]);
                }

                setFilteredData(temp);
                setFilteredItems(temp);
                setMasterData(temp);
                setTotalPages(res.data.totalPages); // Total de páginas

            }).catch((err) => {
                executaBusca(currentPage);
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                executaBusca(currentPage);
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }

        setBusca(props.busca);

    }

    // useEffect(() => {     
    //     if (props.tipo == 'pastor' || props.id == null && props.busca_transcricao == null) {
    //         console.log('teste props.busca');
    //         console.log(props.busca);
    //         executaBusca(currentPage);
    //     }

    // }, [currentPage, props.busca, props.tipo])

    // useEffect(() => {     
    //     if (props.tipo == '' || props.id == null && props.busca_transcricao == null) {
    //         console.log('teste props.busca 2');
    //         console.log(props.busca);
    //         executaBusca(currentPage);
    //     }

    // }, [currentPage, props.busca, props.tipo])


    async function executaBuscaData(page) {

        if (data !== props.data) {
            page = 0;
            setCurrentPage(0);
        }

        try {

            const config = {
                method: 'post',
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': '',
                    'data': props.data,
                    'page': page + 1, // API trabalha com 1 como a primeira página
                    'limit': itemsPerPage,
                    'action': 'getpalavraspordata'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                console.log(res.data);
                console.log('tyrdyr items data');
                for (let i = 0; i < res.data.items.length; ++i) {

                    res.data.items[i].mp3 = "/Pregacoes/" + Moment(res.data.items[i].data).format("YY")
                        + "/" + Moment(res.data.items[i].data).format("MM") + "/" + res.data.items[i].id_youtube + ".mp3";

                    temp.push(res.data.items[i]);
                    console.log(res.data.items[i]);
                }

                setFilteredData(temp);
                setFilteredItems(temp);
                setMasterData(temp);
                setTotalPages(res.data.totalPages); // Total de páginas
                setData('');


            }).catch((err) => {
                executaBuscaData(currentPage);
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                executaBuscaData(currentPage);
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }

        setData(props.data);

    }


    // useEffect(() => {

    //     if (props.data !== '') {            
    //         executaBuscaData(currentPage);
    //     }

    // }, [props.data])

    async function executaBuscaId(page) {

        page = 0;
        setCurrentPage(0);

        try {

            const config = {
                method: 'post',
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': props.id,
                    // 'data': props.id,           
                    'page': page + 1,
                    'limit': itemsPerPage,
                    'action': 'getpalavrasporid'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                console.log(res.data);
                console.log('tyrdyr items data');
                for (let i = 0; i < res.data.items.length; ++i) {

                    res.data.items[i].mp3 = "/Pregacoes/" + Moment(res.data.items[i].data).format("YY")
                        + "/" + Moment(res.data.items[i].data).format("MM") + "/" + res.data.items[i].id_youtube + ".mp3";

                    temp.push(res.data.items[i]);
                    if (props.id !== null) {
                        // enviarAudioPlay(res.data.items[i]);
                    }

                }

                setFilteredData(temp);
                setFilteredItems(temp);
                setMasterData(temp);
                setTotalPages(res.data.totalPages); // Total de páginas
                setId('');

            }).catch((err) => {
                executaBuscaId(currentPage);
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                executaBuscaId(currentPage);
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }

        setId(props.id);

    }

    // useEffect(() => {        

    //     if (props.id !== null && props.busca == '') {           
    //         executaBuscaId(currentPage);
    //     }

    // }, [currentPage, props.id])







    useEffect(() => {

        console.log('novo teste');
        console.log(props.tipo);

        if (props.tipo == 'pastor') {
            executaBusca(currentPage);
        }

        if (props.tipo == 'busca_transcricao') {
            console.log('Entrou no useEffect');
            executaBusca_transcricao(currentPage);
        }

        if (props.tipo == 'data') {
            executaBuscaData(currentPage);
        }

        if (props.id !== null && props.busca == '' && props.tipo == '') {
            executaBuscaId(currentPage);
        }

    }, [currentPage, props.tipo, props.busca, props.busca_transcricao.palavra_transcricao])

    useEffect(() => {

        if (props.tipo == '' && props.id == null) {
            executaBusca();
        }


    }, [currentPage])


    const formatTime = (time) => {

        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);

        if (time)
            return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

        return 'Carregando...';
    };


    const Episodes = () => {

        return (

            // <div>
            filteredItems.length > 0 ?

                filteredItems.map((item) => (

                    <button onClick={() => {
                        console.log('teste dentro de Map');
                        console.log(item); enviarAudioPlay(item)
                    }}>
                        <div className="episode">
                            {/* <audio controls preload="none" style={{height: '2em', verticalAlign:'middle'}}>
                    <source src={item.mp3} type="audio/mpeg" />
                 </audio> */}
                            <h3 className='temaEpisode'>{item.tema}</h3>
                            <h4 className='name-pastor'>{item.pastor}</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', margin: 0, padding: 0 }}><p>Data: {Moment(item.data).format("DD/MM/YYYY")}</p><p>Duração: {item.duracao}</p></div>
                        </div>
                    </button>

                ))

                :

                // itemsjson.map((item) => (

                //     <button onClick={() => {console.log('teste dentro de Map');
                //         console.log(item); enviarAudioPlay(item)}}>
                //     <div className="episode">
                //          {/* <audio controls preload="none" style={{height: '2em', verticalAlign:'middle'}}>
                //             <source src={item.mp3} type="audio/mpeg" />
                //          </audio> */}
                //            <h3>{item.title}</h3>
                //            <h4>{item.pastor}</h4>
                //            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-around'}}><p>Data: {item.pubDate}</p><p>Duração: {formatTime(item.totaltime)}</p></div>
                //          </div>
                //          </button>

                // ))
                <div>
                    {filteredData.map((item) => (

                        <button onClick={() => {
                            console.log('teste dentro de Map');
                            console.log(item); enviarAudioPlay(item)
                        }}>
                            <div className="episode">
                                {/* <audio controls preload="none" style={{height: '2em', verticalAlign:'middle'}}>
                  <source src={item.mp3} type="audio/mpeg" />
               </audio> */}
                                <h3 className='temaEpisode'>{item.tema}</h3>
                                <h4>{item.pastor}</h4>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}><p>Data: {Moment(item.data).format("DD/MM/YYYY")}</p><p>Duração: {item.totaltime}</p></div>
                            </div>
                        </button>

                    ))
                    }

                </div>


            /* </div> */
        )

    }
    let count = 0;
    return (
        <div>
            <Episodes />

            {windowSize.width < 480 ?
                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    forcePage={currentPage}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
                :
                <ReactPaginate
                    previousLabel={"Anterior"}
                    nextLabel={"Próximo"}
                    breakLabel={"..."}
                    pageCount={totalPages}
                    forcePage={currentPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    activeClassName={"active"}
                />
            }
        </div>

        // rowData.forEach(el => {
        //     console.log(el.mp3);

        //     console.log(count = count+1);

        //     // <div className="episode">
        //     //     <audio controls preload="none" style={{height: '2em', verticalAlign:'middle'}}>
        //     //         <source src="https://appsesites.com.br/tib/podcast/pregacao.mp3" type="audio/mpeg" />
        //     //     </audio>
        //     //       <h3>Episódio 1: Introdução</h3>
        //     //       <p>Duração: 30 min</p>
        //     //     </div>

        // })

    )

}