import React, { useState, useEffect } from 'react';
import { TextInputMask, datetimeMask, celPhoneMask } from 'react-masked-text';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import "./App.css";
// import Dropdown from 'react-bootstrap/Dropdown';

// import { toast, ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.min.css';

// import { MdMenu } from "react-icons/md";

import { Link, useLocation } from "react-router-dom";

// import 'bootstrap/dist/css/bootstrap.min.css';

// import gota1 from '../../assets/gota1.png'

// import topo from '../../assets/topo_new.png';
// import topo_desktop from '../../assets/topo_desktop_new.png';

export default function CadastrarPalavra() {

    let { state } = useLocation();

    // console.log(state.doador.nome);

    // url teste local
    const baseurl = `https://podcast.tibmacae.org.br/ApiDoador.php`;

    //url em produção
    //   const baseurl = `ApiDoador.php`;



    const [sucesso, setSucesso] = useState(false);
    const [palavrasarquivo, setPalavrasarquivo] = useState([]);
    // const [nome, setNome] = useState('dfsdfsdfs');
    // const { register, handleSubmit } = useForm();
    // const { handleSubmit, control } = useForm();
    const onSubmit = (data) => {
        alert(JSON.stringify(data));
    };

    const { setValue, register, handleSubmit, reset, control, formState,
        formState: { isSubmitSuccessful }, } = useForm({
            defaultValues: { pastor: "", inicio: "", fim: "", tema: "", idpastor: "" }
            // resolver: yupResolver(schema),
            // validationSchema: fieldsValidationSchema
        });


    function handleGerar(data) {

        const config = {
            method: 'post',           
            url: baseurl,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: 1000,
            data: {
                'mes': data.mes,
                'ano': data.ano,
                'action': 'getqueryvideosmes'
            }
        };

        const req = axios(config).then((res) => {

            var temp = [];
            
            for (let i = 0; i < res.data.length; ++i) {                
                temp.push(res.data[i]);                
            }

            setPalavrasarquivo(temp);
            
            reset({
                nome: "",
                telefone: "",
                dtnascimento: "",
                tiposanguineo: ""
            })


        });

    }


    function handleSalvar(data) {          

        const id_youtube = data.url.substring(data.url.indexOf("v=") + 2);

        console.log('teste data');
        console.log(data);
        
        setSucesso(true);


        const config = {
            method: 'post',
            // url local
            // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=insert_doador`,
            // url: `ApiDoador.php?action=insert_doador`,
            url: baseurl,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            timeout: 1000,
            data: {
                'id': null,
                'pastor': data.pastor,
                'url': data.url,
                'id_youtube': id_youtube,
                'inicio': data.inicio,
                'fim': data.fim,
                'tema': data.tema,
                'transcricao': data.transcricao,
                'data': data.data,
                'idpastor': data.idpastor,                
                'action': 'insert_palavra'
            }
        };

        const req = axios(config).then((res) => {
            //   toast.success('Doação Cadastrada com Sucesso!', {              
            //     onClose: () =>  window.location.href = '/'
            //   })
            // window.location.href = '/';
            reset({
                nome: "",
                telefone: "",
                dtnascimento: "",
                tiposanguineo: ""
            })


        });

        // if(state){

        //   const config = {
        //     method: 'post',
        //     // url local
        //     // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=insert_doador`,
        //     // url: `ApiDoador.php?action=insert_doador`,
        //     url: baseurl,
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     },
        //     timeout:1000,
        //     data: {
        //       'id': state.doador.id,
        //       'nome': data.nome,
        //       'telefone': data.telefone,
        //       'dtnascimento': data.dtnascimento, 
        //       'tiposanguineo': data.tiposanguineo,                 
        //       'action': 'insert_doador'
        //     }
        //   };

        //   const req = axios(config).then((res) => { 
        //     // toast.success('Doação Cadastrada com Sucesso!', {              
        //     //   onClose: () =>  window.location.href = '/doacoes'
        //     // })
        //     // window.location.href = '/';
        //     // reset({
        //     //     id:state.doador.id,
        //     //     nome: "",
        //     //     telefone:"",
        //     //     dtnascimento:"",
        //     //     tiposanguineo:""
        //     //   })


        //    });

        // } else {




        // const config = {
        //     method: 'post',
        //     // url local
        //     // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=insert_doador`,
        //     // url: `ApiDoador.php?action=insert_doador`,
        //     url: baseurl,
        //     headers: {
        //       'Content-Type': 'multipart/form-data'
        //     },
        //     timeout:1000,
        //     data: {
        //       'id': null,
        //       'nome': data.nome,
        //       'telefone': data.telefone,
        //       'dtnascimento': data.dtnascimento, 
        //       'tiposanguineo': data.tiposanguineo,                 
        //       'action': 'insert_doador'
        //     }
        //   };

        // const req = axios(config).then((res) => { 
        // //   toast.success('Doação Cadastrada com Sucesso!', {              
        // //     onClose: () =>  window.location.href = '/'
        // //   })
        //   // window.location.href = '/';
        //   reset({
        //       nome: "",
        //       telefone:"",
        //       dtnascimento:"",
        //       tiposanguineo:""
        //     })


        //  });

        // }




    }

    //   function Menu() {
    //     return (
    //       <Dropdown style={{ width: 150, marginTop: 20 }}>
    //         <Dropdown.Toggle style={{ backgroundColor: 'black', marginLeft: '5%', width: '40%', height: 50 }} id="">
    //           <MdMenu />
    //           {/* <IoMenuOutline style={{}} /> */}
    //         </Dropdown.Toggle>

    //         <Dropdown.Menu style={{ backgroundColor: 'black' }}>
    //           <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/cadastrar">Cadastrar</Link></Dropdown.Item>
    //           <Dropdown.Divider color='white' />
    //           {/* <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doadores">Doadores</Link></Dropdown.Item> */}
    //           <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doadores">Doações</Link></Dropdown.Item>
    //           <Dropdown.Divider color='white' />
    //           <Dropdown.Item> <Link style={{ all: 'unset', color: 'white' }} to="/doacoes">Doadores</Link></Dropdown.Item>
    //           {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
    //             <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
    //         </Dropdown.Menu>
    //       </Dropdown>

    //     );
    //   }

    const [matches, setMatches] = useState(
        window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
        // Atualize os valores do formulário após a montagem do componente, se necessário
        if (state) {
            setValue('nome', state.doador.nome);
            setValue('telefone', state.doador.telefone);
            setValue('dtnascimento', state.doador.dtnascimento);
            setValue('tiposanguineo', state.doador.tiposanguineo);
        }

    }, [state]);

    useEffect(() => {


        window
            .matchMedia("(min-width: 768px)")
            .addEventListener('change', e => setMatches(e.matches));
    }, []);

    // setNome('');

    // useEffect(() => {

    //         reset({
    //             nome: "",
    //             telefone:"",
    //             dtnascimento:""
    //           })

    //   }, [sucesso])
    //   const handleClick = () => toast.success('Mensagem de sucesso', {
    //     onOpen: () => window.alert('Called when I open'),
    //     onClose: () => window.alert('Called when I close')
    //   })


    const [options, setOptions] = useState([]);

    async function setPastor(idPastor) {

        console.log(idPastor.nome);



        try {

            const config = {
                method: 'post',
                //endereço local
                // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=getdoadores`,
                // url: `ApiDoador.php?action=getdoadores`,
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': idPastor,
                    'action': 'getpastores'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                  console.log(res.data[0].nome);
                console.log('teste pastor selecionado');
                //   for (let i = 0; i < res.data.items.length; ++i) {          
                //     temp.push(res.data.items[i]);

                //   }

                setValue("pastor", res.data[0].nome);


                // setPastorSelecionado(res.data);



            }).catch((err) => {
                // optionsPastores();
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                //   optionsPastores();
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }


    }

    async function optionsPastores() {



        try {

            const config = {
                method: 'post',
                //endereço local
                // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=getdoadores`,
                // url: `ApiDoador.php?action=getdoadores`,
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': '',
                    'action': 'getpastores'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                //   console.log(res.data);
                console.log('tyrdyr items data');
                //   for (let i = 0; i < res.data.items.length; ++i) {          
                //     temp.push(res.data.items[i]);

                //   }


                setOptions(res.data);



            }).catch((err) => {
                // optionsPastores();
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                //   optionsPastores();
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }


    }

    useEffect(() => {
        optionsPastores();
    }, [])



    return (

        matches ?

            <div className="app-cadastro-palavra">

                <header className="header">
                    <h1>Podcast TIB Macaé</h1>
                </header>


                <div style={{ display: 'flex', overflowY: 'scroll', height: '400px', alignContent: 'center', alignSelf: 'center', justifySelf: 'center', flexDirection: 'column' }}>
                    <h1 style={{ marginTop: '12%', alignSelf: 'center' }}>Cadastrar Pregações</h1>

                    <form style={{ display: 'flex', alignSelf: 'center', alignItems: 'stretch', justifyContent: 'space-between', alignContent: 'space-around', flexDirection: 'column' }} onSubmit={handleSubmit(data => handleSalvar(data))}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Controller
                                control={control}
                                name="pastor"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Pastor</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="url"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>URL</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="data"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Data</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '95%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Controller
                                control={control}
                                name="inicio"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Início</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="fim"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Fim</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />
                            <Controller
                                name="idpastor"
                                control={control}
                                defaultValue=""
                                render={({ field, onChange, onBlur, value, data }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Id do Pastor</h5></label>
                                        <select {...field} style={{
                                            backgroundColor: '#FFF',
                                            width: '90%',

                                            marginBottom: 15,
                                            color: '#222',
                                            fontSize: 14,
                                            borderRadius: 7,
                                            padding: 10
                                        }}
                                        onChange={(e) => {
                                            field.onChange(e); // Atualizar o React Hook Form
                                            setPastor(e.target.value); // Executar a lógica personalizada
                                          }}
                                            onBlur={onBlur}
                                            selected={value}
                                            value={value}

                                        >
                                            <option value="">Selecione...</option>
                                            {options.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.nome}
                                                </option>

                                            ))}
                                        </select>
                                    </div>
                                )}
                            />


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Controller
                                control={control}
                                name="tema"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Tema</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="transcricao"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Transcrição</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '100%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '6%', alignItems: 'center', justifyContent: 'center' }}>
                            <input style={{
                                backgroundColor: '#59BFFF',
                                width: '100%',
                                height: 45,
                                marginBottom: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 7,
                                alignSelf: 'center',
                                width: '45%'
                            }} type="submit" />
                        </div>

                    </form>

                    <form style={{ display: 'flex', alignSelf: 'center', alignItems: 'stretch', justifyContent: 'space-between', alignContent: 'space-around', flexDirection: 'row' }} onSubmit={handleSubmit(data => handleGerar(data))}>
                        <Controller
                            control={control}
                            name="mes"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label><h5>Mês</h5></label>
                                    <input
                                        style={{
                                            backgroundColor: '#FFF',
                                            width: '75%',

                                            marginBottom: 15,
                                            color: '#222',
                                            fontSize: 14,
                                            borderRadius: 7,
                                            padding: 10
                                        }}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}

                                    />
                                </div>
                            )}
                            defaultValue=""
                        />

                        <Controller
                            control={control}
                            name="ano"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label><h5>Ano</h5></label>
                                    <input
                                        style={{
                                            backgroundColor: '#FFF',
                                            width: '50%',

                                            marginBottom: 15,
                                            color: '#222',
                                            fontSize: 14,
                                            borderRadius: 7,
                                            padding: 10
                                        }}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}

                                    />
                                </div>
                            )}
                            defaultValue=""
                        />

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '6%', alignItems: 'center', justifyContent: 'center' }}>
                            <button style={{
                                // backgroundColor: '#59BFFF',
                                width: '100%',
                                height: 45,
                                marginBottom: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 7,
                                alignSelf: 'center',
                                width: '45%',

                            }} type="submit" >Gerar para Arquivo</button>
                        </div>
                    </form>

                    <div>
                    {console.log('fdfdsfs dfsdfsdds')}
                        {console.log(palavrasarquivo)}
                       {palavrasarquivo.map((item, index) => (
                        
                        <p key={index}>{index === palavrasarquivo.length -1 ? `@{url =  "${item.url}"; start = "${item.inicio}"; end = "${item.fim}"}`: `@{url =  "${item.url}"; start = "${item.inicio}"; end = "${item.fim}"},`}</p>
                        // <p>" @{url =  "`${item.url}"; start = "`'${item.url}"; end = "${item.url}+" ,"</p>
                                  
                             ))
                            }
                    </div>

                </div>

            </div>

            :

            <div className="app-cadastro-palavra">

                <header className="header">
                    <h1>Podcast TIB Macaé</h1>
                </header>


                <div style={{ display: 'flex', overflowY: 'scroll', height: '500px', alignContent: 'center', alignSelf: 'center', justifySelf: 'center', flexDirection: 'column' }}>
                    <h1 style={{ marginTop: '12%', alignSelf: 'center' }}>Cadastrar Pregações</h1>

                    <form style={{ display: 'flex', alignSelf: 'center', alignItems: 'stretch', justifyContent: 'space-between', alignContent: 'space-around', flexDirection: 'column' }} onSubmit={handleSubmit(data => handleSalvar(data))}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Controller
                                control={control}
                                name="pastor"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Pastor</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="url"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>URL</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="data"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Data</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '95%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Controller
                                control={control}
                                name="inicio"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Início</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="fim"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Fim</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />
                            <Controller
                                name="idpastor"
                                control={control}
                                defaultValue=""
                                render={({ field, onChange, onBlur, value }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Id do Pastor</h5></label>
                                        <select {...field} style={{
                                            backgroundColor: '#FFF',
                                            width: '90%',

                                            marginBottom: 15,
                                            color: '#222',
                                            fontSize: 14,
                                            borderRadius: 7,
                                            padding: 10
                                        }}
                                            //    onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            value={value}

                                        >
                                            <option value="">Selecione...</option>
                                            {options.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.nome}
                                                </option>

                                            ))}
                                        </select>
                                    </div>
                                )}
                            />


                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                            <Controller
                                control={control}
                                name="tema"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Tema</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '80%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                            <Controller
                                control={control}
                                name="transcricao"
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label><h5>Transcrição</h5></label>
                                        <input
                                            style={{
                                                backgroundColor: '#FFF',
                                                width: '100%',

                                                marginBottom: 15,
                                                color: '#222',
                                                fontSize: 14,
                                                borderRadius: 7,
                                                padding: 10
                                            }}
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}

                                        />
                                    </div>
                                )}
                                defaultValue=""
                            />

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '6%', alignItems: 'center', justifyContent: 'center' }}>
                            <input style={{
                                backgroundColor: '#59BFFF',
                                width: '100%',
                                height: 45,
                                marginBottom: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 7,
                                alignSelf: 'center',
                                width: '45%'
                            }} type="submit" />
                        </div>

                    </form>

                    <form style={{ display: 'flex', alignSelf: 'center', alignItems: 'stretch', justifyContent: 'space-between', alignContent: 'space-around', flexDirection: 'column' }} onSubmit={handleSubmit(data => handleGerar(data))}>
                        <Controller
                            control={control}
                            name="mes"
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <label><h5>Mês</h5></label>
                                    <input
                                        style={{
                                            backgroundColor: '#FFF',
                                            width: '100%',

                                            marginBottom: 15,
                                            color: '#222',
                                            fontSize: 14,
                                            borderRadius: 7,
                                            padding: 10
                                        }}
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}

                                    />
                                </div>
                            )}
                            defaultValue=""
                        />

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '6%', alignItems: 'center', justifyContent: 'center' }}>
                            <button style={{
                                // backgroundColor: '#59BFFF',
                                width: '100%',
                                height: 45,
                                marginBottom: 5,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: 7,
                                alignSelf: 'center',
                                width: '45%',

                            }} type="submit" >Gerar para Arquivo</button>
                        </div>
                    </form>

                    <div>
                    {console.log('fdfdsfs dfsdfsdds')}
                        {console.log(palavrasarquivo)}
                       {palavrasarquivo.map((item, index) => (
                        
                        <p key={index}>{index === palavrasarquivo.length -1 ? `@{url =  "${item.url}"; start = "${item.inicio}"; end = "${item.fim}"}`: `@{url =  "${item.url}"; start = "${item.inicio}"; end = "${item.fim}"},`}</p>
                        // <p>" @{url =  "`${item.url}"; start = "`'${item.url}"; end = "${item.url}+" ,"</p>
                                  
                             ))
                            }
                    </div>

                </div>

            </div>

    );
}

