import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { PodcastGrid } from './PodcastGrid';
import "./App.css";
import ComponenteAudio from './ComponenteAudio';
import ComponenteBuscaAudio from './ComponenteBuscaAudio';
import Howl from "react-howler";
import Modal from './Modal';
import Menu from "./Menu";
import AudioShare from './AudioShare';
import axios from 'axios';
import { FaWhatsapp } from "react-icons/fa";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

import { useSearchParams } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';

function Home() {

    // url teste local
    const baseurl = `https://podcast.tibmacae.org.br/ApiDoador.php`;

    //url em produção
    //   const baseurl = `ApiDoador.php`;

    const [showCalendar, setShowCalendar] = useState(false);
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [busca, setBusca] = useState('');
    const [busca_transcricao, setBusca_transcricao] = useState('');
    const [data, setData] = useState('');
    const [tipo, setTipo] = useState('');

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpenBusca, setIsModalOpenBusca] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const openModalBusca = () => {setIsModalOpenBusca(true); toggleSidebar();} 
    const closeModal = () => setIsModalOpen(false);
    const closeModalBusca = () => setIsModalOpenBusca(false);


    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const [audiocarregado, setAudiocarregado] = useState(false);
    const [currentAudio, setCurrentAudio] = useState(null); // Mantém o áudio selecionado
    const audioRef = useRef(null); // Referência para o elemento <audio>

    const [audioPlay, setAudioPlay] = useState('');
    const [itemPlay, setItemPlay] = useState(null);

    const [rowData, setRowData] = useState([]);


    const [isSidebarVisible, setIsSidebarVisible] = useState(false);
    const [submenuvisible, setSubmenuvisible] = useState(false);


    const toggleCalendar = () => {
        setShowCalendar((prev) => !prev);
    };

    const onSubmit = (data) => {
            alert(JSON.stringify(data));
        };
    
        const { setValue, register, handleSubmit, reset, control, formState,
            formState: { isSubmitSuccessful }, } = useForm({
                defaultValues: { palavra_transcricao: "", inicio: "", fim: "", tema: "", idpastor: "" }
                // resolver: yupResolver(schema),
                // validationSchema: fieldsValidationSchema
            });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        // Adiciona o listener de resize
        window.addEventListener('resize', handleResize);

        // Remove o listener ao desmontar o componente
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    

//     const requestNotificationPermission = async () => {
//         const permission = await Notification.requestPermission();
//         if (permission === "granted") {
//           console.log("Permissão concedida para notificações.");
//         } else {
//           console.error("Permissão negada para notificações.");
//         }
//       };

//       navigator.serviceWorker.ready.then(async(registration) => {
//     return registration.pushManager.getSubscription().then(async(subscription) => {
//       if (subscription) {
//         console.log("Usuário já está inscrito:", subscription);
//         return subscription;
//       }

//       // Criar uma nova assinatura
//       const newSubscription = await registration.pushManager.subscribe({
//         userVisibleOnly: true,
//         applicationServerKey: "BHfbhjx3sWP9yY97Zkg_xtAyJm4qQuz2Hc2b4YLGWJKc4USFBoS8P66ivO4eH8Zpa-NbBvzykzFFh9ALNq_LVU0",
//       });

//       console.log("Nova assinatura criada:", newSubscription);
//       return newSubscription;
//     });
//   })
//   .catch((error) => {
//     console.error("Erro ao acessar o pushManager:", error);
//   });

//       const subscribeUser = async (registration) => {
//         const subscription = await registration.pushManager.subscribe({
//           userVisibleOnly: true,
//           applicationServerKey: process.env.REACT_APP_PUBLIC_VAPID_KEY,
//         });
      
//         // Enviar ao backend
//         await fetch("https://fcm.googleapis.com/fcm/send", {
//           method: "POST",
//           body: JSON.stringify(subscription),
//           headers: {
//             "Content-Type": "application/json",
//           },
//         });
//       };

//     useEffect(() => {
//         requestNotificationPermission();
//         // subscribeUser();

//     }, [])

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault(); // Evita o prompt automático do navegador
            setDeferredPrompt(e); // Armazena o evento para uso posterior
            setIsInstallable(true); // Ativa o botão de instalação
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Mostra o prompt de instalação
            const choiceResult = await deferredPrompt.userChoice;
            if (choiceResult.outcome === 'accepted') {
                console.log('Usuário aceitou a instalação.');
            } else {
                console.log('Usuário recusou a instalação.');
            }
            setDeferredPrompt(null); // Limpa o evento
        }
    };

    const playAudio = async (audioSrc) => {
        // Atualiza o áudio atual
        setCurrentAudio(audioSrc);        
        // Reinicia o player após atualizar o src
        if (audioRef.current) {
            audioRef.current.load(); // Recarrega o áudio
            audioRef.current.play().catch((err) => console.error("Erro ao reproduzir:", err));
        }
    };

    // Função para converter segundos em mm:ss
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        // console.log(time);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    async function salvarLog(tipolog, id_palavra = null) {

        try {

            const config = {
                method: 'post',                
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': '',
                    'id_palavra': id_palavra,
                    'tipolog': tipolog,
                    'action': 'salvarlog'
                }
            };

            const req = await axios(config).then((res) => {
                console.log(res);                
            }).catch((err) => {
                // optionsPastores();
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                //   optionsPastores();
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }

    }


    const AdvancedAudioPlayer = () => {

        const [isPlaying, setIsPlaying] = useState(audiocarregado);
        const [currentTime, setCurrentTime] = useState(0); // Tempo atual do áudio
        const [duration, setDuration] = useState(0); // Duração total do áudio
        const audioRef = useRef(null);
        const [progress, setProgress] = useState(0);
        const [volume, setVolume] = useState(1);

        // const handleTimeUpdate = () => {
        //   if (audioRef.current) {
        //     setCurrentTime(audioRef.current.currentTime); // Atualiza o tempo atual
        //   }
        // };

        // useEffect(() => {

            

        // },[])

        const handleTimeUpdate = () => {
            setCurrentTime(audioRef.current.currentTime);
            if (audioRef.current) {
                // const currentTime = audioRef.current.currentTime;
                // const duration = audioRef.current.duration;
                setProgress((currentTime / duration) * 100);
            }
        };

        const handleLoadedMetadata = () => {
            if (audioRef.current) {
                setDuration(audioRef.current.duration); // Obtém a duração total do áudio
            }
        };

        // const [duration, setDuration] = useState(0);

        const audioContextRef = useRef(null); // Referência para o AudioContext
        const sourceNodeRef = useRef(null); // Referência para o MediaElementSourceNode
        const gainNodeRef = useRef(null); // Referência para o GainNode


        const handlePlayPause = () => {

            // if (audioRef.current) {
            //   // Criar o AudioContext apenas uma vez
            //   if (!audioContextRef.current) {
            //     audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            //   }

            //   // Criar o MediaElementSourceNode apenas uma vez
            //   if (!sourceNodeRef.current) {
            //     // Conectar o <audio> ao AudioContext
            //     sourceNodeRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);

            //     // Criar o GainNode para controle de volume
            //     gainNodeRef.current = audioContextRef.current.createGain();

            //     // Conectar os nós: MediaElementSourceNode -> GainNode -> Destination
            //     sourceNodeRef.current.connect(gainNodeRef.current).connect(audioContextRef.current.destination);

            //     // Configurar o volume inicial
            //     gainNodeRef.current.gain.value = 20; // Volume padrão (1 = 100%)
            //   }
            // }

            if (audioRef.current.paused) {
                audioRef.current.play();
            } else {
                audioRef.current.pause();
            }
        };

        // const handleTimeUpdate = () => {
        //   const current = audioRef.current.currentTime;
        //   const duration = audioRef.current.duration;
        //   setProgress((current / duration) * 100);
        // };

        const handleSeek = (event) => {

            const seekTo = parseFloat(event.target.value);
            console.log((event.target.value / 100) * audioRef.current.duration);
            setProgress(seekTo);
            if (audioRef.current) {
                audioRef.current.currentTime = (event.target.value / 100) * audioRef.current.duration;
                // audioRef.current.seek(seekTo);
            }
            // const seekTime = (event.target.value / 100) * audioRef.current.duration;
            // audioRef.current.currentTime = seekTime;
            // setProgress(event.target.value);
        };

        const handleVolumeChange = (event) => {
            const newVolume = event.target.value;
            setVolume(newVolume);
            audioRef.current.volume = newVolume;
        };

        const handleStop = () => {
            setIsPlaying(false);
            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reinicia o áudio
            }
        };


        const setupEqualizer = (audioContext, source) => {
            const frequencies = [60, 170, 350, 1000, 3500, 10000]; // Graves a agudos
            const filters = frequencies.map((freq) => {
                const filter = audioContext.createBiquadFilter();
                filter.type = "peaking"; // Tipo de filtro para equalização
                filter.frequency.value = freq; // Freqüência central
                filter.Q.value = 1; // Controle da largura da banda
                filter.gain.value = 0; // Ganho inicial
                return filter;
            });

            // Conectar os filtros em série
            filters.reduce((prev, curr) => {
                prev.connect(curr);
                return curr;
            });

            // Conectar a saída do último filtro
            filters[filters.length - 1].connect(audioContext.destination);

            // Conectar o source ao primeiro filtro
            source.connect(filters[0]);

            return filters; // Retorna os filtros para controle
        };

        const setupNoiseReduction = (audioContext, source) => {
            const highPass = audioContext.createBiquadFilter();
            highPass.type = "highpass";
            highPass.frequency.value = 10; // Remove sons abaixo de 100 Hz (ruído grave)

            const lowPass = audioContext.createBiquadFilter();
            lowPass.type = "lowpass";
            lowPass.frequency.value = 15000; // Remove sons acima de 15 kHz (ruído agudo)

            // Conectar os filtros em cadeia
            source.connect(highPass).connect(lowPass).connect(audioContext.destination);
        };

        const setupCompressor = (audioContext, source) => {
            const compressor = audioContext.createDynamicsCompressor();
            compressor.threshold.value = -30; // Nível de ativação (dB)
            compressor.knee.value = 40; // Curvatura da compressão
            compressor.ratio.value = 12; // Taxa de compressão
            compressor.attack.value = 0.01; // Tempo para começar a comprimir
            compressor.release.value = 0.25; // Tempo para parar de comprimir

            // Conectar o compressor
            source.connect(compressor).connect(audioContext.destination);
        };

        const setupReverb = async (audioContext, source) => {
            const convolver = audioContext.createConvolver();
            const response = await fetch("/reverb-impulse-response.wav"); // Arquivo de resposta de impulso
            const arrayBuffer = await response.arrayBuffer();

            // Decodifica o arquivo de resposta de impulso
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

            // Define o buffer no ConvolverNode
            convolver.buffer = audioBuffer;

            source.connect(convolver).connect(audioContext.destination);
        };

        const setupPanning = (audioContext, source) => {
            const panner = audioContext.createStereoPanner();
            panner.pan.value = 0; // -1 para esquerda, 1 para direita, 0 para centro

            source.connect(panner).connect(audioContext.destination);
        };



        useEffect(() => {
            
            if (audioRef.current) {
                // Criar o AudioContext apenas uma vez
                if (!audioContextRef.current) {
                    // audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
                    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)({
                        sampleRate: 96000, // Reamostra o áudio para 48 kHz
                    });
                }                

                // Criar o MediaElementSourceNode apenas uma vez
                if (!sourceNodeRef.current) {
                    // Conectar o <audio> ao AudioContext
                    // sourceNodeRef.current = audioContextRef.current.createMediaElementSource(audioRef.current);

                    const sourceNode = audioContextRef.current.createMediaElementSource(audioRef.current);

                    // Criar o GainNode para controle de volume
                    gainNodeRef.current = audioContextRef.current.createGain();

                    const analyser = audioContextRef.current.createAnalyser();
                    const gainNode = audioContextRef.current.createGain();

                    // Conectar os nós: MediaElementSourceNode -> GainNode -> Destination
                    // sourceNodeRef.current.connect(gainNodeRef.current).connect(audioContextRef.current.destination);

                    sourceNode.connect(analyser); // Conectar ao analisador
                    analyser.connect(gainNode).connect(audioContextRef.current.destination); // Saída final

                    sourceNodeRef.current = { sourceNode, analyser, gainNode };

                    // Analisar o volume
                    const dataArray = new Uint8Array(analyser.frequencyBinCount);
                    analyser.getByteFrequencyData(dataArray);

                    // Calcular o volume médio
                    const averageVolume = dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length || 1; // Evitar NaN

                    // Ajustar o ganho (volume desejado)
                    const targetVolume = 5; // Meta de volume
                    gainNode.gain.value = targetVolume / averageVolume;

                    // setupEqualizer(audioContextRef.current, sourceNode);
                    // No seu código principal:
                    const filters = setupEqualizer(audioContextRef.current, sourceNodeRef.current.sourceNode);
                    // Ajustar as frequências dinamicamente:
                    filters[0].gain.value = 20; // Aumentar os graves, por exemplo

                    setupNoiseReduction(audioContextRef.current, sourceNodeRef.current.sourceNode);

                    setupCompressor(audioContextRef.current, sourceNodeRef.current.sourceNode);

                    // setupReverb(audioContextRef.current, sourceNodeRef.current.sourceNode);

                    setupPanning(audioContextRef.current, sourceNodeRef.current.sourceNode);                    

                    // Configurar o volume inicial
                    //gainNodeRef.current.gain.value = 20; // Volume padrão (1 = 100%)
                }
            }
            //};

            // normalizeVolume();
        }, [currentAudio]);


        const audioSrc = "path-to-your-audio-file.mp3"; // Substitua pelo caminho do seu arquivo de áudio
        const url = "https://podcast.tibmacae.org.br/?id="

        const handleShareToWhatsApp = () => {
            const message = encodeURIComponent(
                `Confira a esta pregação abençoada: ${url}${itemPlay.id}`
            );
            const whatsappUrl = `https://wa.me/?text=${message}`;
            window.open(whatsappUrl, "_blank"); // Abre o link do WhatsApp em uma nova aba
        };

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: "100%",
                justifyContent: 'center',
                alignItems: 'center',
                margin: "0 auto",
                textAlign: "center"
            }}>



                <p className='title_audio_executando'><b>Tema:</b> {itemPlay.tema}</p>
                <div className='buttoncontrols'>
                    {/* <button className='audiobutton' onClick={handlePrev}>⏮️</button> */}
                    <button className='audiobutton' onClick={handlePlayPause}>
                        {isPlaying ? "⏸️ Pausar" : "▶️ Tocar"}
                    </button>
                    <button className='audiobutton' onClick={handleStop}>⏹️ Parar</button>
                    {/* <button className='audiobutton' onClick={handleNext}>⏭️</button> */}
                </div>


                {/* <div>
        <button onClick={handlePlayPause}>Play / Pause</button>
      </div> */}
                <div className='audio-container'>
                    <audio
                        controls
                        ref={audioRef}
                        src={currentAudio}
                        autoPlay
                        onLoad={() => setDuration(audioRef.current.duration())}

                        onLoadedMetadata={handleLoadedMetadata} // Obtém a duração do áudio
                        // controls
                        onTimeUpdate={handleTimeUpdate}
                    />
                    {/* <div style={{ marginTop: "10px", display: "flex", justifyContent: "center", gap: "10px" }}> */}

                    {/* Botão de compartilhar no WhatsApp */}
                    <button
                        onClick={handleShareToWhatsApp}
                        style={{
                            backgroundColor: "#25D366",
                            color: "white",
                            border: "none",
                            borderRadius: "50px",
                            padding: "10px 15px",
                            fontSize: "16px",
                            cursor: "pointer",
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                        }}
                    >
                        <FaWhatsapp size={20} />

                    </button>
                    {/* </div> */}
                </div>
                {/* <div style={{width:'100%'}}>
        <input
          type="range"
          min="0"
          max="100"
          value={progress}
          onChange={handleSeek}
          style={{ width: "100%" }}
        />
      </div> */}
                {/* <div>
        <label>Volume: </label>
        <input
          type="range"
          min="0"
          max="1"
          step="0.1"
          value={volume}
          onChange={handleVolumeChange}
        />
      </div> */}

                {/* <div className='progress'>
      <div style={{ margin: "0px 0" }}>
         
        </div>
      <p className='title'> */}
                {/* {currentTime.toFixed(2)} */}
                {/* {formatTime(currentTime.toFixed(2))} / {formatTime(duration)} */}
                {/* </p>
        </div> */}
                {/* <AudioShare audio={itemPlay.mp3} /> */}

            </div>
        );
    };

    const receberAudio = (valor) => {
        console.log('teste receber valor');
        console.log(valor);
        setAudioPlay(valor);
        playAudio(valor.mp3);
        setAudiocarregado(true);
    };  

    const toggleSidebar = () => {
        setIsSidebarVisible(!isSidebarVisible);
        setSubmenuvisible(!submenuvisible);
    };      

    let salvoLogHome= false;

    useEffect(() => {

        if (!salvoLogHome) {
            salvarLog('count_home');
            salvoLogHome = true;
        }

        // salvarLog('count_home');


        // try {
        //     const response = fetch("/feedpregacao.xml")
        //         .then(response => response.text())
        //         .then(str => new window.DOMParser().parseFromString(str, 'text/xml'))
        //         .then(data => {

        //             // console.log(data);                                
        //             const itemList = data.querySelectorAll('item');
        //             console.log(itemList);

        //             const items = [];
        //             itemList.forEach(el => {
        //                 console.log(el.querySelector('pubDate').textContent);
        //                 items.push({
        //                     pubDate: new Date(el.querySelector('pubDate').textContent),
        //                     title: el.querySelector('title').innerHTML,
        //                     mp3: el.querySelector('enclosure').getAttribute('url'),
        //                     description: el
        //                         .querySelector('description')
        //                         .textContent.replace(/(<([^>]+)>)/gi, ''),
        //                 });
        //             });
        //             setRowData(items)
        //         });

        //     if (!response.ok) {
        //         throw new Error(`Erro de Rede: ${response.status}`);
        //     }

        //     const xmlText = response.text();
        //     const parser = new DOMParser();
        //     const xmlDoc = parser.parseFromString(xmlText, "application/xml");

        // } catch (error) {
        //     console.error("Erro ao buscar XML:", error);
        // }
    }, []);

    const [contador, setContador] = useState(0);

    const handleClick = (episode) => {
        // setContador(contador + 1);
        console.log(`Botão clicado! Contador: ${episode}`);
    };

    const atualizarValor = (novoValor) => {
        setItemPlay(novoValor);        
        openModal();        
        playAudio(novoValor.mp3);
        setAudiocarregado(true);     
        salvarLog('count_palavra', novoValor.id);   
    };

    const [options, setOptions] = useState([]);
    const [convidados, setConvidados] = useState([]);

    async function optionsConvidados() {

        try {

            const config = {
                method: 'post',                
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': '',
                    'tipo': 'convidado',
                    'action': 'getpastores'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                for (let i = 0; i < res.data.length; ++i) {
                    temp.push({ label: res.data[i].nome, id: res.data[i].id, link: "/services/web-development" });

                }         

                setConvidados(temp);

            }).catch((err) => {
                // optionsPastores();
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                //   optionsPastores();
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }


    }

    async function optionsPastores() {

        try {

            const config = {
                method: 'post',                
                url: baseurl,
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                timeout: 10000,
                data: {
                    'id': '',
                    'action': 'getpastores'
                }
            };

            const req = await axios(config).then((res) => {
                var temp = [];

                for (let i = 0; i < res.data.length; ++i) {
                    temp.push({ label: res.data[i].nome, id: res.data[i].id, link: "/services/web-development" });

                }         

                setOptions(temp);

            }).catch((err) => {
                // optionsPastores();
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('A solicitação foi cancelada devido a timeout');
                //   optionsPastores();
                // Aqui você pode fazer algo, como tentar novamente a consulta
                // ou exibir uma mensagem de erro para o usuário
            } else {
                // Lidar com outros erros de rede ou do servidor
                console.error('Erro:', error.message);
                throw error;
            }

        }


    }

    useEffect(() => {        
        optionsPastores();
        optionsConvidados();
    }, [])

    const menuItems = [
        {
            label: "Home",
            link: "/",
        },
        {
            label: "Pregador",
            children: options,
            // children: [
            //     { label: "Geraldo Geremias", id:'teste', link: "/services/web-development" },
            //     { label: "Pr. Wendell", link: "/services/app-development" },
            //     { label: "Irmã Rutilea", link: "/services/app-development" },
            //     { label: "Pr. Iran", link: "/services/app-development" },
            //     { label: "Pr. Gledson", link: "/services/app-development" },
            //     { label: "Irmã Elce", link: "/services/app-development" },
            //     { label: "Pr. Elmo", link: "/services/app-development" },
            //     { label: "Pr. Francisco", link: "/services/app-development" },
            //     { label: "Pr. Odenildo", link: "/services/app-development" },
            //     { label: "Pr. Rafael Antunes", link: "/services/app-development" },
            //   ],
        },
        {
            label: "Convidados",
            children: convidados,            
        },
        // {
        //     children: [
        //             { label: "Geraldo Geremias", id:'teste', link: "/services/web-development" },
        //     ]
        // },

    ];

    const receberDados = (valor) => {  
        console.log('teste menu');
        console.log(valor);      
        setBusca(valor);
        setTipo('pastor');
        toggleSidebar();
    };

    const setItemMenu = (valor) => {  
        // console.log('teste menu');
        // console.log(valor);              
    };

    const receberData = (valor) => {       
        setData(valor);
        setTipo('data');
        toggleSidebar();
    };

    function handleSalvar(data) {      
        
        setTipo("busca_transcricao");
        setBusca_transcricao(data);
        setBusca('');
        closeModalBusca();
        
        console.log('teste data form');
        console.log(data);

    }

    return (

        <div className="app">
            {/* Header */}

            <header className="header">
                <div style={{}}>
                    <h1>Podcast TIB Macaé</h1>

                    {/* Floating Button */}
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <button className="toggle-btn" onClick={toggleSidebar}>
                            ☰
                        </button>
                        <aside className={`sidebar ${isSidebarVisible ? "visible" : ""}`}>
                            <button className="close-btn" onClick={toggleSidebar}>
                                ✖
                            </button>
                            <Menu onClick={setItemMenu} submenuvisible={submenuvisible} onChange={receberDados} enviarDado={receberDados} enviarData={receberData} items={menuItems} />
                            <ul>
                            <div        
        style={{
          background: "#bcc44a",
          color: "white",
          padding: "10px",
          cursor: "pointer",
          textAlign: "center",
          borderRadius: "5px",
        }}
        onClick={openModalBusca}
      >
         Buscar Pregação
      </div>
      </ul>

                        </aside>
                    </div>



                </div>
            </header>

            {/* Main Content */}
            <div className="content">
                <h2>Últimos Episódios</h2>
                {/* <h1>{windowSize.width}</h1>        */}
                <div className="episodes">
                    <ComponenteBuscaAudio tipo={tipo} busca_transcricao={busca_transcricao} id={searchParams.get("id")} busca={busca} data={data} onClick={atualizarValor} enviarAudio={receberAudio} rssfeed="/feedpregacao.xml" />
                    <Modal isOpen={isModalOpen} onClose={closeModal} className="modal-overlay">
                        {itemPlay ?
                            <div style={{ width: '100%' }} className="">
                                <AdvancedAudioPlayer />
                            </div>

                            : 'Carregando...'}

                        {/* <h2>Conteúdo do Modal</h2>
        <p>Este é um exemplo de modal em React!</p> */}
                    </Modal>

                    <Modal isOpen={isModalOpenBusca} onClose={closeModalBusca} className="modal-overlay">
                        
                            {/* <div style={{ width: '20%' }} className=""> */}
                                <form style={{ display: 'flex', flexDirection:'column', alignSelf:'center', width:'100%'}} onSubmit={handleSubmit(data => handleSalvar(data))}>
                                
                                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                                            <Controller
                                                                control={control}
                                                                name="palavra_transcricao"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', justifyContent:'center', alignItems:'center', flexDirection: 'column' }}>
                                                                        <label><h5>O pregador mensionou a palavra:</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '100%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            />
                                
                                                            {/* <Controller
                                                                control={control}
                                                                name="url"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>URL</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '80%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            />
                                
                                                            <Controller
                                                                control={control}
                                                                name="data"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>Data</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '95%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            /> */}
                                
                                                        </div>

                                                        {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                            <Controller
                                                                control={control}
                                                                name="pastor"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>O pregador mensionou a palavra:</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '100%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            />
                                                                                                                            
                                                        </div> */}
                                
                                                        {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                            <Controller
                                                                control={control}
                                                                name="inicio"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>Início</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '80%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            />
                                
                                                            <Controller
                                                                control={control}
                                                                name="fim"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>Fim</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '80%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            />
                                                            <Controller
                                                                name="idpastor"
                                                                control={control}
                                                                defaultValue=""
                                                                render={({ field, onChange, onBlur, value }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>Id do Pastor</h5></label>
                                                                        <select {...field} style={{
                                                                            backgroundColor: '#FFF',
                                                                            width: '90%',
                                
                                                                            marginBottom: 15,
                                                                            color: '#222',
                                                                            fontSize: 14,
                                                                            borderRadius: 7,
                                                                            padding: 10
                                                                        }}
                                                                            //    onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                                                            value={value}
                                
                                                                        >
                                                                            <option value="">Selecione...</option>
                                                                            {options.map((option) => (
                                                                                <option key={option.id} value={option.id}>
                                                                                    {option.nome}
                                                                                </option>
                                
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                )}
                                                            />
                                
                                
                                                        </div> */}
                                
                                                        {/* <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                                            <Controller
                                                                control={control}
                                                                name="tema"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>Tema</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '80%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            />
                                
                                                            <Controller
                                                                control={control}
                                                                name="transcricao"
                                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                                        <label><h5>Transcrição</h5></label>
                                                                        <input
                                                                            style={{
                                                                                backgroundColor: '#FFF',
                                                                                width: '100%',
                                
                                                                                marginBottom: 15,
                                                                                color: '#222',
                                                                                fontSize: 14,
                                                                                borderRadius: 7,
                                                                                padding: 10
                                                                            }}
                                                                            onChange={onChange}
                                                                            onBlur={onBlur}
                                                                            selected={value}
                                
                                                                        />
                                                                    </div>
                                                                )}
                                                                defaultValue=""
                                                            />
                                
                                                        </div> */}
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: '6%', alignItems: 'center', justifyContent: 'center' }}>
                                                            <input style={{
                                                                backgroundColor: '#59BFFF',
                                                                width: '100%',
                                                                height: 45,
                                                                marginBottom: 5,
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: 7,
                                                                alignSelf: 'center',
                                                                width: '45%'
                                                            }} type="submit" />
                                                        </div>
                                
                                                    </form>
                            {/* </div> */}


                        {/* <h2>Conteúdo do Modal</h2>
        <p>Este é um exemplo de modal em React!</p> */}
                    </Modal>

                </div>
            </div>

        </div>
    );
}


export default Home;