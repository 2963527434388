import React, { useState, useEffect } from "react";
import './Menu.css';
import Modal from './Modal';

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const Menu = ({ items, enviarDado, enviarData, submenuvisible }) => {

  const [value, setValue] = useState(new Date());

  const formatDate = (date) => {
    console.log(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const buscaData = (value) => {
    setValue(value);
    console.log(formatDate(value));
    enviarDataParaTela(formatDate(value));
    closeModal();
  }

  const [showCalendar, setShowCalendar] = useState(false);

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
    
    const [openIndex, setOpenIndex] = useState(null); // Rastreia o índice do submenu aberto
  
    const toggleSubmenu = (index) => {
      setOpenIndex(openIndex === index ? null : index); // Alterna entre abrir e fechar
    };

    const enviarDataParaTela = (valor) => {
      enviarData(valor);
      };

    const enviarParaTela = (valor) => {
        enviarDado(valor);
      };

    const handleLinkClick = (value) => {
        console.log('Valor recebido:', value);
        
        enviarParaTela(value);
        setOpenIndex(!openIndex);
        
        // setLinkValue(value); // Atualiza o estado ou faz algo com o valor
      };

      useEffect(()=>{
        console.log('index: ', submenuvisible);
        if(!submenuvisible){
            toggleSubmenu();
        }


    },[submenuvisible]);

    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstallable, setIsInstallable] = useState(false);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
          e.preventDefault(); // Evita o prompt automático do navegador
          setDeferredPrompt(e); // Armazena o evento para uso posterior
          setIsInstallable(true); // Ativa o botão de instalação
        };
    
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    
        return () => {
          window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
      }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
          deferredPrompt.prompt(); // Mostra o prompt de instalação
          const choiceResult = await deferredPrompt.userChoice;
          if (choiceResult.outcome === 'accepted') {
            console.log('Usuário aceitou a instalação.');
          } else {
            console.log('Usuário recusou a instalação.');
          }
          setDeferredPrompt(null); // Limpa o evento
        }
      };
  
    return (
      <ul className="menu">
        {items.map((item, index) => (
          <MenuItem
            key={index}
            item={item}
            isOpen={openIndex === index}
            handleClick={handleLinkClick}
            onToggle={() => toggleSubmenu(index)}
          />
          
        ))}
        <li className="menu-item"><div><a href='#' onClick={() => handleInstallClick()}>Instalar o App</a></div></li>
        <li className="menu-item">

          {/* <div style={{ width: "200px", margin: "0 auto" }}> */}
      {/* Botão ou item de menu */}
      <div        
        style={{
          background: "#007bff",
          color: "white",
          padding: "10px",
          cursor: "pointer",
          textAlign: "center",
          borderRadius: "5px",
        }}
        onClick={openModal}
      >
         Data da Pregação
      </div>

      

      {/* Exibição condicional do calendário */}
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={closeModal} className="modal-overlay-calendar">
          <Calendar calendarType="gregory" onChange={(value) => buscaData(value)} value={value} />
        </Modal>
        
        // <ul
        //   className="submenu-calendar"
        // >
        //   <li>
        //   <Calendar />
        //   </li>
        // </ul>
      )}
    {/* </div> */}
        </li>
      </ul>
      
    );
  };
  
  const MenuItem = ({ item, isOpen, onToggle, handleClick }) => {
    const hasChildren = item.children && item.children.length > 0;
  
    return (
      <li className="menu-item">
        
        <div onClick={onToggle}>
          {item.link ? <a href={item.link}>{item.label}</a> : item.label}
          
          {/* {hasChildren && <span>{isOpen ? "▲" : "▶"}</span>} */}
        </div>
        {hasChildren && isOpen && (
          <ul className="submenu">
            {item.children.map((child, index) => (
              <li key={index} className="menu-item">
                {child.link ? 
                <a href="#"
                onClick={(e) => {
                    e.preventDefault(); // Evita navegação
                    handleClick(child.label); // Passa o valor para o pai
                  }}
                // href={child.link}
                >{child.label}</a> : child.label}
              </li>
            ))}
            
          </ul>
        )}
      </li>
      
    );
  };

export default Menu;